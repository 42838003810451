import * as React from "react";
import { Link } from "gatsby";
import Layout from '../../../components/Layout';
import SocialMedia from '../../../images/digital-marketing-social-media.jpg';
import SEO from '../../../components/Seo';
import CTA from "../../../components/CTA";

const SocialMediaMarketing = () => {

    return(
        <Layout>
            <SEO title="Social Media Marketing for Healthcare" keywords="HealthOne Digital, digital advertising, google adwords, PPC for healthcare, paid social media ads"/>
            <section>
                <div className="pt-10 bg-blue-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                            <div className="mx-auto px-4 sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                                <div className="py-8 sm:py-8 lg:py-24">
                                    <h1 className="mt-4 mb-5 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                         <span className="block">Social Media Marketing</span> 
                                         <span className="block mt-0 lg:mt-2">For Healthcare</span>
                                    </h1>
                                    <span className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                        <Link className="hover:text-white" to="/services">SERVICES</Link> 
                                        <span> / </span> 
                                        <Link className="hover:text-white" to="/services/digital-marketing">DIGITAL MARKETING</Link>
                                        <span> / </span>
                                        SOCIAL MEDIA MARKETING
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content */}
           

            <section>
                <div className="bg-white overflow-hidden py-8 lg:py-16">
                    <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>

                        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                            <div>
                                <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-900 sm:text-4xl">Bolster your healthcare brand and be seen as a trusted source of information.</h2>
                            </div>
                        </div>

                        <div className="mt-2 lg:grid lg:grid-cols-2 lg:gap-8">

                            <div className="relative lg:row-start-1 lg:col-start-2">
                                <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                                    <defs>
                                        <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                                </svg>
                                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                                <figure>
                                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                                        <img className="rounded-lg shadow-lg object-cover object-center" src={SocialMedia} alt="A mobile phone showing social media app icons." width="1184" height="1376"/>
                                    </div>
                                </figure>
                                </div>
                            </div>

                            <div className="mt-8 lg:mt-0">
                         
                                <div className="mt-10 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    Sharing and marketing the content that you create on various social media platforms is a great way to bolster your healthcare brand and be seen as a trusted source of information.
                                    </p>
                                    <p className="text-lg">
                                    Another major benefit of being active on social media is the ability to counter misinformation and raise awareness about important health issues. Misinformation is ripe on social media and healthcare providers like yourself are an invaluable asset in the fight against it.
                                    </p>
                                    <p className="text-lg">
                                    We can tailor your social media presence to be reflective of you and your healthcare practice by developing a social media strategy and consistent style guide. We can also help you reach your target patient audience and promote your content through advertising campaigns.
                                    </p>
                                </div>

                           
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

           
            {/* CTA */}
            <CTA/>
        </Layout>
    )
}

export default SocialMediaMarketing;